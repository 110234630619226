import { Component, ElementRef, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { PopoverController } from '@ionic/angular';
import { ConfigService } from '../config.service';
import { LanguagePopoverPage } from '../language-popover/language-popover.page';
import { NotificationsPopoverComponent } from '../notifications-popover/notifications-popover.component';
import { ToastController } from '@ionic/angular';
import { WebsocketService } from '../services/websocket.service';
import { Subscription } from 'rxjs';
import { SharedAssetService } from '../services/shared-asset.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent implements OnInit, OnDestroy {

  dropdown = false;
  menuItems = [];
  menuDropDownItems = [];
  accountName: string;
  newassets = [];
  badge = 0;
  showBadge = false;
  user: any = {};
  timestamp: string = "";
  notificationsSubscription: Subscription;
  changeNameSubscription: Subscription;
  changeBadgeNumber: Subscription;

  @ViewChild('productbtn', { read: ElementRef, static: true }) productbtn: ElementRef;

  constructor(private sharedAssetService: SharedAssetService, private socketService: WebsocketService, public toastController: ToastController, private apiService: ConfigService, private popoverCtrl: PopoverController) {
    this.user = this.apiService.retrieveMyData();

    this.apiService.getAgentNotifications(this.user.public_key).subscribe((response) => {
      let notifications: any;
      notifications = response;
      // console.log(notifications);
      for (let notification of notifications) {
        if (this.user.role == "Παραγωγός") {
          if (!notification.sender_notification1_has_opened)
            this.badge++;
          if (notification.production_verified_date != "" && !notification.sender_notification2_has_opened)
            this.badge++;
        }
        else {
          if (!notification.receiver_notification1_has_opened)
            this.badge++;
          if (notification.production_verified_date != "" && !notification.receiver_notification2_has_opened)
            this.badge++;
        }
      }
      if (this.badge != 0)
        this.showBadge = true;

      if (this.user.role == "Παραγωγός") {
        this.accountName = this.user.name + " " + this.user.surname;
        this.menuItems = [
          // {
          //   title: 'Πίνακας Ελέγχου',
          //   icon: 'home-outline',
          //   path: ''
          // },
          {
            title: 'TOPMENU.my-production',
            icon: 'leaf-outline',
            path: '/top-menu/my-fields'
          },
          {
            title: 'TOPMENU.tracking',
            icon: 'location-outline',
            path: '/top-menu/tracking'
          }
        ];

        this.menuDropDownItems = [
          {
            title: 'TOPMENU.my-profile',
            icon: 'person-outline',
            path: '/top-menu/profile'
          },
          {
            title: 'TOPMENU.log-out',
            icon: 'log-out-outline',
            path: '/login'
          }
        ];
      }
      else {
        this.accountName = this.user.surname;
        this.menuItems = [
          // {
          //   title: 'Πίνακας Ελέγχου',
          //   icon: 'home-outline',
          //   path: ''
          // },
          {
            title: 'TOPMENU.productions',
            icon: 'leaf-outline',
            path: '/top-menu/my-fields'
          },
          {
            title: 'TOPMENU.my-silo',
            icon: 'home-outline',
            path: '/top-menu/my-silos-merchant'
          },
          {
            title: 'TOPMENU.tracking',
            icon: 'location-outline',
            path: '/top-menu/tracking'
          },
        ];

        this.menuDropDownItems = [
          {
            title: 'TOPMENU.my-assets',
            icon: 'wallet-outline',
            path: '/top-menu/my-assets-merchant'
          },
          {
            title: 'TOPMENU.my-profile',
            icon: 'person-outline',
            path: '/top-menu/profile'
          },
          {
            title: 'TOPMENU.log-out',
            icon: 'log-out-outline',
            path: '/login'
          }
        ];
      }
    },
      async (error) => {   //Error callback
        console.log(error.error)
      })
  }

  ngOnInit() {
    this.notificationsSubscription = this.socketService.notifyNotificationsChanged().subscribe((value) => {
      // console.log(value);
      const user = this.apiService.retrieveMyData();
      if (value.receiver_public_key == user.public_key && value.timestamp != this.timestamp) {
        this.presentToast("Η παραγωγή " + value.field_id + " εστάλη επιτυχώς! Παρακαλώ επιβεβαιώστε την παραλαβή της.");
        this.timestamp = value.timestamp;
        this.badge++;
        this.showBadge = true;
      }
      else if (value.sender_public_key == user.public_key && value.timestamp != this.timestamp) {
        this.presentToast("H παραλαβή της παραγωγής " + value.field_id + " επιβεβαιώθηκε επιτυχώς!");
        this.timestamp = value.timestamp;
        this.badge++;
        this.showBadge = true;
      }
    });

    this.changeNameSubscription = this.sharedAssetService.notifyNameChanged().subscribe((value) => {
      this.accountName = value;
    });

    this.changeBadgeNumber = this.sharedAssetService.notifyBadgeNumberChanged().subscribe((value) => {
      if (value == "increase") {
        this.badge++;
        if (this.badge == 1)
          this.showBadge = true;
      }
      else {
        this.badge--;
        if (this.badge == 0)
          this.showBadge = false;
      }

    });
  }

  ngOnDestroy(): void {
    this.notificationsSubscription.unsubscribe();
    this.changeNameSubscription.unsubscribe();
  }


  hideDropdown(event) {
    const xTouch = event.clientX;
    const yTouch = event.clientY;

    const rect = this.productbtn.nativeElement.getBoundingClientRect();
    const topBoundary = rect.top + 2;
    const leftBoundary = rect.left + 2;
    const rightBoundary = rect.right - 2;

    if (xTouch < leftBoundary || xTouch > rightBoundary || yTouch < topBoundary) {
      this.dropdown = false;
    }
  }

  async openLanguagePopover(ev) {
    const popover = await this.popoverCtrl.create({
      component: LanguagePopoverPage,
      event: ev
    })
    await popover.present();
  }

  async showNotifications(ev: any) {
    const popover = await this.popoverCtrl.create({
      component: NotificationsPopoverComponent,
      event: ev,
      animated: true,
      showBackdrop: true,
      cssClass: 'contact-popover'
    });
    return await popover.present();
  }

  deleteStorageIfLogOut(item) {
    if (item == 'TOPMENU.log-out') {
      localStorage.clear();
    }
  }

  async presentToast(message) {
    this.toastController.create({
      header: 'New Notification!',
      message: message,
      position: 'bottom',
      duration: 4000,
      animated: true,
      cssClass: 'my-custom-class',
      buttons: [
        {
          side: 'end',
          text: 'X',
          role: 'cancel',
          handler: () => {
            console.log('Close clicked');
          }
        }
      ]
    }).then((obj) => {
      obj.present();
    });
  }

}
