import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';

import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { SplashScreen } from '@ionic-native/splash-screen/ngx';
import { StatusBar } from '@ionic-native/status-bar/ngx';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { GoogleMaps } from '@ionic-native/google-maps';

import { Geolocation } from '@ionic-native/geolocation/ngx';
import { NativeGeocoder } from '@ionic-native/native-geocoder/ngx';
import { HttpClientModule, HttpClient } from '@angular/common/http';
import { NativeStorage } from '@ionic-native/native-storage/ngx';
import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { IonicStorageModule } from '@ionic/storage';
import { LanguagePopoverPageModule } from './language-popover/language-popover.module';
import { AnQrcodeModule } from 'an-qrcode';
import { NotificationsPopoverComponent } from "./notifications-popover/notifications-popover.component";
import { AgmCoreModule } from '@agm/core';
import { DatePipe } from '@angular/common';
import { HeaderComponent } from './header/header.component';
import { AvatarModule } from 'ngx-avatar';
import { WebsocketService } from './services/websocket.service';
import { CalendarModule } from 'ion2-calendar';
import { MatPaginatorModule } from '@angular/material';
import { MatTableModule } from '@angular/material';
import { MatSortModule } from '@angular/material';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations'; 

const avatarColors = ["rgb(245, 189, 67)"];

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, 'assets/i18n/', '.json');
}
@NgModule({
  declarations: [AppComponent, NotificationsPopoverComponent],
  entryComponents: [NotificationsPopoverComponent],
  imports: [ BrowserModule, IonicModule.forRoot(), AnQrcodeModule, HttpClientModule, AvatarModule.forRoot({
    colors: avatarColors
  }),AppRoutingModule, 
    AgmCoreModule.forRoot({
    apiKey: 'AIzaSyDiAz4YPl18h3HDaH3v7GjnNvZp_CIGiXs'}),
    IonicStorageModule.forRoot({
    name: '__mydb',
    driverOrder: ['indexeddb', 'sqlite', 'websql']
      
  }),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    }),
    LanguagePopoverPageModule,
    CalendarModule,
    MatSortModule,
    MatTableModule,
    MatPaginatorModule,
    BrowserAnimationsModule

  ],
  providers: [
    StatusBar,
    SplashScreen,
    Geolocation,
    NativeGeocoder,
    NativeStorage,
    { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
    GoogleMaps,
    DatePipe,
    HeaderComponent,
    NativeGeocoder,
    WebsocketService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
