import { Injectable } from "@angular/core";
import { CanActivate, ActivatedRouteSnapshot, RouterStateSnapshot, Router, ActivationStart } from "@angular/router";
import { Observable } from "rxjs";

@Injectable({
    providedIn: "root",
})
export class AuthGuard implements CanActivate {
    constructor(private router: Router) { }


    canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean> | Promise<boolean> | boolean {
        if (localStorage.getItem("userInfo")) {
            const currentUser = JSON.parse(localStorage.getItem('userInfo'));
            if (currentUser) {                
                // authorised so return true
                return true;
            }
        }

        this.router.navigate(["/login"], { queryParams: { returnUrl: state.url } });
        return false;
    }
}
