import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { PopoverController } from '@ionic/angular';
import { ConfigService } from '../config.service';
import { DatePipe } from '@angular/common';
import { SharedAssetService } from '../services/shared-asset.service';


@Component({
  selector: 'app-notifications-popover',
  templateUrl: './notifications-popover.component.html',
  styleUrls: ['./notifications-popover.component.scss'],
})
export class NotificationsPopoverComponent implements OnInit {

  notifications: any[] = [];
  isShown: boolean = false;

  constructor(private sharedAssetService: SharedAssetService, private router: Router, private apiService: ConfigService, private popoverCtrl: PopoverController, public datepipe: DatePipe) {

    var user = this.apiService.retrieveMyData();
    this.apiService.getAgentNotifications(user.public_key).subscribe((response) => {
      let notifications: any;
      notifications = response;
      // console.log(notifications);

      var status, notifName, record_id, icon, path, title1, title2, details1, details2, time, timestamp
      if (notifications.length != 0) {
        for (let notification of notifications) {
          record_id = notification.record_id;
          if (user.role == "Παραγωγός") {
            if (notification.sender_notification1_has_opened)
              status = "clicked"
            else
              status = "noclicked"
            notifName = "sender_notification1"
            icon = 'icon-waiting'
            path = 'top-menu/sub-menu/transfer-production/' + notification.record_id
            title1 = 'NOTIFICATIONS.pop_up_message1'
            title2 = 'NOTIFICATIONS.pop_up_message2'
            details1 = 'NOTIFICATIONS.details2'
            details2 = ""
            time = this.timeSince(notification.production_sent_date)+ ' ago',
            timestamp = Date.parse(this.datepipe.transform(notification.production_sent_date, 'MM/dd/yyyy HH:mm'));
            var notification_info = { record_id: record_id, status: status, notifName: notifName, icon: icon, path: path, title1: title1, title2:title2, details1: details1, details2: details2, time: time, timestamp: timestamp };
            this.notifications.push(Object.assign({}, notification_info));

            if (notification.production_verified_date != "") {
              if (notification.sender_notification2_has_opened)
                status = "clicked"
              else
                status = "noclicked"
              notifName = "sender_notification2"
              icon = 'icon-check'
              path = 'top-menu/sub-menu/transfer-production/' + notification.record_id
              title1 = 'NOTIFICATIONS.pop_up_message3'
              title2 = 'NOTIFICATIONS.pop_up_message4'
              details1 = 'NOTIFICATIONS.details1'
              details2 = notification.receiver_name
              time = this.timeSince(notification.production_verified_date)+ ' ago',
              timestamp = Date.parse(this.datepipe.transform(notification.production_verified_date, 'MM/dd/yyyy HH:mm'));
              var notification_info = { record_id: record_id, status: status, notifName: notifName, icon: icon, path: path, title1: title1, title2:title2, details1: details1, details2: details2, time: time, timestamp: timestamp };
              this.notifications.push(Object.assign({}, notification_info));
            }
          }
          else {
            if (notification.receiver_notification1_has_opened)
              status = "clicked"
            else
              status = "noclicked"
            notifName = "receiver_notification1"
            icon = 'icon-waiting'
            path = 'top-menu/sub-menu/production-receipt-merchant/' + notification.record_id
            title1 = 'NOTIFICATIONS.pop_up_message5'
            title2 = 'NOTIFICATIONS.pop_up_message6'
            details1 = 'NOTIFICATIONS.details1'
            details2 = notification.sender_name
            time = this.timeSince(notification.production_sent_date)+ ' ago',
            timestamp = Date.parse(this.datepipe.transform(notification.production_sent_date, 'MM/dd/yyyy HH:mm'));
            var notification_info = { record_id: record_id, status: status, notifName: notifName, icon: icon, path: path, title1: title1, title2:title2, details1: details1, details2: details2, time: time, timestamp: timestamp };
            this.notifications.push(Object.assign({}, notification_info));

            if (notification.production_verified_date != "") {
              if (notification.receiver_notification2_has_opened)
                status = "clicked"
              else
                status = "noclicked"
              notifName = "receiver_notification2"
              icon = 'icon-check'
              path = 'top-menu/sub-menu/production-receipt-merchant/' + notification.record_id
              title1 = 'NOTIFICATIONS.pop_up_message7'
              title2 = 'NOTIFICATIONS.pop_up_message8'
              details1 = 'NOTIFICATIONS.details2'
              details2 = ""
              time = this.timeSince(notification.production_verified_date)+ ' ago',
              timestamp = Date.parse(this.datepipe.transform(notification.production_verified_date, 'MM/dd/yyyy HH:mm'));
              var notification_info = { record_id: record_id, status: status, notifName: notifName, icon: icon, path: path, title1: title1, title2:title2, details1: details1, details2: details2, time: time, timestamp: timestamp };
              this.notifications.push(Object.assign({}, notification_info));
            }
          }
        }
        this.notifications.sort((a, b) => b.timestamp - a.timestamp)
        
      } else {
        this.isShown = true;
      }
    },
      async (error) => {   //Error callback
        console.log(error.error)
      })
  }

  ngOnInit() { }

  compareIndexFound(a, b) {
    if (a.indexFound < b.indexFound) { return -1; }
    if (a.indexFound > b.indexFound) { return 1; }
    return 0;
  }

  goToDetails(item: any) {

    var obj = {}
    if (item.status == "noclicked") {
      if (item.notifName == "sender_notification1")
        obj = { sender_notification1_has_opened: true };
      else if (item.notifName == "sender_notification2")
        obj = { sender_notification2_has_opened: true };
      else if (item.notifName == "receiver_notification1")
        obj = { receiver_notification1_has_opened: true };
      else if (item.notifName == "receiver_notification2")
        obj = { receiver_notification2_has_opened: true };

      this.apiService.updateNotification(obj, item.record_id).subscribe((response) => {
        let record: any;
        record = response;
        // console.log(record);
        if (item.status == "noclicked")
          this.sharedAssetService.updateBadgeNumber("decrease");
      },
        async (error) => {   //Error callback
          console.log(error.error)
        })
    }
    this.router.navigate([item.path]);
    this.sharedAssetService.updateID(item.record_id);
    this.popoverCtrl.dismiss();
  }

  openNotifications() {
    this.router.navigate(['/top-menu/my-notifications/']);
  }

  timeSince(date) {
    if (typeof date !== 'object') {
      date = new Date(date);
    }
  
    let seconds = Math.floor((+new Date() - date) / 1000);
    var intervalType;
  
    var interval = Math.floor(seconds / 31536000);
    if (interval >= 1) {
      intervalType = 'year';
    } else {
      interval = Math.floor(seconds / 2592000);
      if (interval >= 1) {
        intervalType = 'month';
      } else {
        interval = Math.floor(seconds / 86400);
        if (interval >= 1) {
          intervalType = 'day';
        } else {
          interval = Math.floor(seconds / 3600);
          if (interval >= 1) {
            intervalType = "hour";
          } else {
            interval = Math.floor(seconds / 60);
            if (interval >= 1) {
              intervalType = "minute";
            } else {
              interval = seconds;
              intervalType = "second";
            }
          }
        }
      }
    }
  
    if (interval > 1 || interval === 0) {
      intervalType += 's';
    }
  
    return interval + ' ' + intervalType;
  }

}
