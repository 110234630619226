import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from 'src/core/authentication.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'top-menu/my-fields',
    pathMatch: 'full'
  },
  {
    path: 'supply-chain',
    loadChildren: () => import('./supply-chain/supply-chain.module').then(m => m.SupplyChainPageModule)
  },
  {
    path: 'login',
    loadChildren: () => import('./login/login.module').then(m => m.LoginPageModule)
  },
  {
    path: 'language-popover',
    loadChildren: () => import('./language-popover/language-popover.module').then(m => m.LanguagePopoverPageModule)
  },
  {
    path: 'registration',
    loadChildren: () => import('./registration/registration.module').then( m => m.RegistrationPageModule)
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule)
  },
  {
    path: 'my-fields',
    loadChildren: () => import('./my-fields/my-fields.module').then( m => m.MyFieldsPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./profile/profile.module').then( m => m.ProfilePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'top-menu',
    loadChildren: () => import('./top-menu/top-menu.module').then( m => m.TopMenuPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sowing/:myid',
    loadChildren: () => import('./sowing/sowing.module').then( m => m.SowingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'production-producer/:myid',
    loadChildren: () => import('./production-producer/production-producer.module').then( m => m.ProductionProducerPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'harvest-producer/:myid',
    loadChildren: () => import('./harvest-producer/harvest-producer.module').then( m => m.HarvestProducerPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'transfer-production/:myid',
    loadChildren: () => import('./transfer-production/transfer-production.module').then( m => m.TransferProductionPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'productions-field-merchant/:myid',
    loadChildren: () => import('./productions-field-merchant/productions-field-merchant.module').then( m => m.ProductionsFieldMerchantPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'production-processing-merchant/:myid',
    loadChildren: () => import('./production-processing-merchant/production-processing-merchant.module').then( m => m.ProductionProcessingMerchantPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'production-receipt-merchant/:myid',
    loadChildren: () => import('./production-receipt-merchant/production-receipt-merchant.module').then( m => m.ProductionReceiptMerchantPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'sub-menu',
    loadChildren: () => import('./sub-menu/sub-menu.module').then( m => m.SubMenuPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'nutritional-value/:myid',
    loadChildren: () => import('./nutritional-value/nutritional-value.module').then( m => m.NutritionalValuePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'my-assets-merchant',
    loadChildren: () => import('./my-assets-merchant/my-assets-merchant.module').then( m => m.MyAssetsMerchantPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'my-silos-merchant',
    loadChildren: () => import('./my-silos-merchant/my-silos-merchant.module').then( m => m.MySilosMerchantPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'quality/:myid',
    loadChildren: () => import('./quality/quality.module').then( m => m.QualityPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'silo-details-merchant/:myid/:quantity',
    loadChildren: () => import('./silo-details-merchant/silo-details-merchant.module').then( m => m.SiloDetailsMerchantPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'verify',
    loadChildren: () => import('./account-verification/account-verification.module').then(m => m.AccountVerificationModule)  
  },
  {
    path: 'tracking',
    loadChildren: () => import('./tracking/tracking.module').then( m => m.TrackingPageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'my-notifications',
    loadChildren: () => import('./my-notifications/my-notifications.module').then( m => m.MyNotificationsPageModule),
    canActivate: [AuthGuard]
  },



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule { }
