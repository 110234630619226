import { ConfigService } from './../config.service';
import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})

export class SharedAssetService {

  jsonData: any = {};
  constructor(private http: ConfigService) { }
  x = [];
  myOBJ;
  nameChanged= new Subject<any>();
  badgeNumberChanged = new Subject<any>();
  IDChanged = new Subject<any>();


  ngOnInit() {
    
  }

  notifyNameChanged() {
    return this.nameChanged.asObservable();
  }

  updateName(data) {
    this.nameChanged.next(data);
  }

  notifyBadgeNumberChanged() {
    return this.badgeNumberChanged.asObservable();
  }

  updateBadgeNumber(data) {
    this.badgeNumberChanged.next(data);
  }

  notifyIDChanged() {
    return this.IDChanged.asObservable();
  }

  updateID(data) {
    this.IDChanged.next(data);
  }

}
