import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ConfigService {

  private serverUrl: string = environment.serverUrl;

  private httpOptions = {
    headers: new HttpHeaders({ 'Content-Type': 'application/json', 'Access-Control-Allow-Origin': '*' })
  };

  constructor(private http: HttpClient) { }

  
  saveMyData(temp: any) {
    localStorage.setItem('userInfo', JSON.stringify(temp));
  }

  retrieveMyData() {
    const myUser = JSON.parse(localStorage.getItem('userInfo'));
    return (myUser);
  }


  logIn(data: any) {
    return this.http.post(this.serverUrl + 'authentication', data, this.httpOptions);
  }

  register(data: any) {
    return this.http.post(this.serverUrl + 'registration', data, this.httpOptions);
  }

  verify(data: any) {
    return this.http.post(this.serverUrl + 'verification', data, this.httpOptions);
  }


  getAllAgents() {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.get(this.serverUrl + 'agents', httpOptions2)
  }

  getProducer(public_key: any) {
    return this.http.get(this.serverUrl + 'agent/' + public_key)
  }

  getAgent(public_key: any) {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.get(this.serverUrl + 'agents/' + public_key, httpOptions2)
  }

  editAgent(data: any) {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.post(this.serverUrl + 'agents/update', data, httpOptions2)
  }

  updateAgentAssets(data: any) {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.post(this.serverUrl + 'agents/assets/update', data, httpOptions2)
  }

  getAgentAssets(public_key: any) {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.get(this.serverUrl + 'agents/assets/' + public_key, httpOptions2)
  }

  getSiloDetails(public_key: any, asset_id: any) {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.get(this.serverUrl + 'agents/' + public_key + '/assets/'+ asset_id, httpOptions2)
  }

  createRecord(data: any) {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.post(this.serverUrl + 'records', data, httpOptions2)
  }

  updateSowing(data: any, record_id: any) {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.post(this.serverUrl + 'records/' + record_id + '/sowing', data, httpOptions2)
  }

  updateProduction(data: any, record_id: any) {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.post(this.serverUrl + 'records/' + record_id + '/production', data, httpOptions2)
  }

  updateHarvest(data: any, record_id: any) {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.post(this.serverUrl + 'records/' + record_id + '/harvest', data, httpOptions2)
  }

  updateRiceType(data: any, record_id: any) {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.post(this.serverUrl + 'records/' + record_id + '/rice_type', data, httpOptions2)
  }

  updateProductionReceipt(data: any, record_id: any) {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.post(this.serverUrl + 'records/' + record_id + '/production_receipt', data, httpOptions2)
  }

  updateProductionProcessing(data: any, record_id: any) {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.post(this.serverUrl + 'records/' + record_id + '/production_processing', data, httpOptions2)
  }

  updateQuality(data: any, record_id: any) {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.post(this.serverUrl + 'records/' + record_id + '/quality', data, httpOptions2)
  }

  updateNotification(data: any, record_id: any) {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.post(this.serverUrl + 'records/' + record_id + '/notification', data, httpOptions2)
  }

  getAllRecords() {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.get(this.serverUrl + 'records', httpOptions2)
  }

  getRecords(record_id: any) {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.get(this.serverUrl + 'records/' + record_id, httpOptions2)
  }

  getAgentRecords(public_key: any) {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.get(this.serverUrl + 'records/agent/' + public_key, httpOptions2)
  }

  getAgentNotifications(public_key: any) {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.get(this.serverUrl + 'agents/' + public_key + '/notifications', httpOptions2)
  }

  getCertificate(cert_name: string) {
    return this.http.get(this.serverUrl + 'public/' + cert_name, { 
      headers: {'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token}, 
      responseType: 'blob' 
    })
  }

  transferRecord(data: any, record_id: any) {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.post(this.serverUrl + 'records/' + record_id + '/transfer', data, httpOptions2)
  }

  deleteRecord (record_id: any) {
    var httpOptions2 = {
      headers: new HttpHeaders({ 'Access-Control-Allow-Methods': '*','Authorization': 'Bearer ' + JSON.parse(localStorage.getItem('userInfo')).token })
    };
    return this.http.delete(this.serverUrl + 'records/' + record_id, httpOptions2)
  }

}
