import { Injectable } from '@angular/core';
import {io}  from "socket.io-client";
import { environment } from 'src/environments/environment';
import { Observable, Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class WebsocketService {
  notificationsChanged= new Subject<any>();

  socket: any;
  readonly uri: string = environment.serverSocketUrl + "notifications";


  constructor() {
    this.socket = io(this.uri, { transports: ['websocket', 'polling'] });
  }
  

  connect(obj) {
    this.socket.on('connect', function(){ console.log('Successful socket connection!')});  
    this.emit('notification message', obj); 
    this.listen('reply').subscribe((obj) => {
      this.notificationsChanged.next(obj);
    });
    this.disconnect();
  }

  notifyNotificationsChanged() {
    return this.notificationsChanged.asObservable();
  }

  disconnect() {
    this.socket.on('disconnect', function(){ console.log('Socket disconnected!')});  
  }

  listen(eventName: string) {
    return new Observable((subscriber) => {
      this.socket.on(eventName, (data) => {
        subscriber.next(data);
      })
    });
  }

  emit(eventName: string, data: any) {
    this.socket.emit(eventName, data);
  }
}
