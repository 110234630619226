// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  // serverUrl:'http://160.40.49.104:10000/api/',
  // serverUrl:'http://localhost:8000/api/',
  // serverUrl: 'http://160.40.53.213:10000/api/',
  // appUrl: 'http://160.40.52.147:8200'
  // serverUrl: 'http://160.40.53.89:8000/',
  // serverSocketUrl: 'http://160.40.53.89:8001/',
  // appUrl: 'http://160.40.53.89:8100'
  serverUrl: 'https://rumore.iti.gr/rest/',
  serverSocketUrl: 'https://rumore.iti.gr/',
  appUrl: 'https://rumore.iti.gr'
  // serverUrl: 'http://localhost:8000/',
  // appUrl: 'http://localhost:8100'
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
